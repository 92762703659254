import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: '登录',
        meta: {
            title: '材搬配后台管理系统'
        },
        component: () => import('../views/Login/Login.vue')
    },
    //下面路径映射分了children和菜单的分级无关，只是说明下面的所有路径映射都是再/首页中的，所以写道了childern里
    {
        path: '/',
        name: '首页',
        meta: {
            title: '材搬配后台管理系统'
        },
        component: () => import('../views/Home.vue'),
        children: [
            //对应刚进入首页或点击管理员头像或点击左上角后切换回的当前管理员信息页面
            {
                path: '/bindex/admininfo',
                name: '管理员信息',
                component: () => import('../views/admininfo/managerInfo.vue')
            },
            {
                path: '/bindex/index',
                name: '首页',
                component: () => import('../views/index/index.vue'),
            },
             //平台配置
             {
                path: '/configuration/configuration',
                name: '平台配置',
                component: () => import('../views/configuration/configuration.vue')
            },
            {
                path: '/configuration/fenconfig',
                name: '分公司配置',
                component: () => import('../views/configuration/fenconfig.vue')
            },
            {
                path: '/member/member',
                name: '会员等级',
                component: () => import('../views/configuration/member/member.vue'),
            },
            {
                path: '/payment/payment',
                name: '支付参数',
                component: () => import('../views/configuration/payment/payment.vue'),
            },
            //用户管理
            {
                path: '/usermanage/usermanage',
                name: '用户管理',
                component: () => import('../views/usermanage/usermanage.vue'),
            },
             // 管理员管理
             {
                path: '/usermanaget/usermanaget',
                name: '管理员管理',
                component: () => import('../views/usermanage/usermanagetwo.vue'),
            },
            //商家管理
            {
                path: '/fenbusinessmanage/fenbusinessmanage',
                name: '分公司管理',
                component: () => import('../views/businessmanage/fenbusinessmanage.vue'),
                meta: {keepAlive: true }
            },
            {
                path: '/fenbusinessdetail/fenbusinessdetail',
                name: '分公司详情',
                component: () => import('../views/businessmanage/fenbusinessdetail.vue'),
                meta: {keepAlive: true,isfan:true }
            },
            {
                path: '/businessmanage/businessmanage',
                name: '商家管理',
                component: () => import('../views/businessmanage/businessmanage.vue'),
                meta: {keepAlive: true }
            },
            {
                path: '/zxbusinessmanage/zxbusinessmanage',
                name: '装修公司管理',
                component: () => import('../views/businessmanage/zxbusinessmanage.vue'),
                meta: {keepAlive: true }
            },
            {
                path: '/businesspaymanage/businesspaymanage',
                name: '支付参数配置',
                component: () => import('../views/businessmanage/businesspaymanage.vue'),
                meta: {keepAlive: true }
            },
            {
                path: '/businesssh/businesssh',
                name: '商家审核',
                component: () => import('../views/businessmanage/businesssh.vue'),
                meta: {keepAlive: true }
            },
            {
                path: '/businessrz/businessrz',
                name: '商家认证审核',
                component: () => import('../views/businessmanage/businessrz.vue'),
                meta: {keepAlive: true }
            },
            {
                path: '/businessdetail/businessdetail',
                name: '机构详情',
                component: () => import('../views/businessmanage/businessdetail.vue'),
                meta: {isfan: true,isfan:true }
            },
            {
                path: '/zxbusinessdetail/zxbusinessdetail',
                name: '装修公司详情',
                component: () => import('../views/businessmanage/zxbusinessdetail.vue'),
                meta: {isfan: true }
            },
            {
                path: '/businessitemdetail/businessitemdetail',
                name: '机构信息修改',
                component: () => import('../views/businessmanage/businessitemdetail.vue'),
            },
            {
                path: '/zxbusinessitemdetail/zxbusinessitemdetail',
                name: '装企机构信息修改',
                component: () => import('../views/businessmanage/zxbusinessitemdetail.vue'),
            },
            //商家分类管理
            {
                path: '/companyCategory/companyCategory',
                name: '商家分类管理',
                component: () => import('../views/companyCategory/companyCategory.vue'),
            },
            // 商品分类管理页面
            {
                path: '/goodsCategory/goodsCategory',
                name: '商品分类管理',
                component: () => import('../views/goodsCategory/goodsCategory.vue'),
            },
            // 供应商管理页面
            {
                path: '/supplier/supplier',
                name: '供应商管理',
                component: () => import('../views/supplier/supplier.vue'),
                meta: {keepAlive: true }
            },
            // 供应商详情
            {
                path: '/supplierdetail/supplierdetail',
                name: '供应商详情',
                component: () => import('../views/supplier/supplierdetail.vue'),
                meta: {isfan: true }
            },
             // 供应商详情
             {
                path: '/supplieritemdetail/supplieritemdetail',
                name: '供应商信息修改',
                component: () => import('../views/supplier/supplierdetail.vue'),
                meta: {isfan: true }
            },
            // 品牌管理
            {
                path: '/brand/brand',
                name: '品牌管理',
                component: () => import('../views/brand/brand.vue'),
            },
            // 广告管理
            {
                path: '/advertising/advertising',
                name: '广告管理',
                component: () => import('../views/advertising/advertising.vue'),
            },
            // 库房管理
            {
                path: '/warehouse/warehouse',
                name: '库房管理',
                component: () => import('../views/warehouse/warehouse.vue'),
            },
            // 系列管理
            {
                path: '/series/series',
                name: '系列管理',
                component: () => import('../views/series/series.vue'),
            },
             // 规格管理
             {
                path: '/specification/specification',
                name: '规格管理',
                component: () => import('../views/specification/specification.vue'),
            },
            // 颜色管理
            {
                path: '/color/color',
                name: '颜色管理',
                component: () => import('../views/color/color.vue'),
            },
              //单位管理
            {
            path: '/unit/unit',
            name: '单位管理',
            component: () => import('../views/unit/unit.vue'),
            },
            // 商品管理
            {
            path: '/goodsmanage/goodsmanage',
            name: '商品管理',
            component: () => import('../views/goodsmanage/goodsmanage.vue'),
            meta: {keepAlive: true }
            },
            // 活动管理
            {
                path: '/activity/activity',
                name: '活动管理',
                component: () => import('../views/activity/activity.vue'),
                meta: {keepAlive: true }
            },
            //  活动商品管理
            {
                path: '/evengoods/evengoods',
                name: '活动商品管理',
                isshow:true,
                component: () => import('../views/activity/evengoods/evengoods.vue'),
                meta: {isfan: true }
            },
             // 福利商品 
             {
                path: '/welfaregoods/welfaregoods',
                name: '商品福利',
                isshow:true,
                component: () => import('../views/welfaregoods/welfaregoods.vue'),
            },
            // 工长管理
            {
                path: '/foreman/foreman',
                name: '工长管理',
                component: () => import('../views/foreman/foreman.vue'),
            },
            // centralized 集采管理
            {
                path: '/centralized/centralized',
                name: '集采管理',
                component: () => import('../views/centralized/centralized.vue'),
            },
            // driver 司机管理
            {
                path: '/driver/driver',
                name: '司机管理',
                component: () => import('../views/driver/driver.vue'),
            },
             // post 订单管理
             {
                path: '/post/post',
                name: '订单管理',
                component: () => import('../views/post/post.vue'),
                meta: {keepAlive: true }
            },
            {
                path: '/dxgpost/dxgpost',
                name: '待修改订单',
                component: () => import('../views/post/dxgpost.vue'),
                meta: {keepAlive: true }
            },
            {
                path: '/fzpost/fzpost',
                name: '分账订单',
                component: () => import('../views/post/fzpost.vue'),
                meta: {keepAlive: true }
            },
            {
                path: '/post/recycledpost',
                name: '订单回收站',
                component: () => import('../views/post/recycledpost.vue'),
                meta: {keepAlive: true }
            },
            {
                path: '/payinfos/payinfos',
                name: '支付信息',
                component: () => import('../views/payinfos/payinfos.vue'),
            },
            {
                path: '/rzpost/rzpost',
                name: '商家认证订单',
                component: () => import('../views/post/rzpost.vue')
            },
              // address 地址管理
              {
                path: '/address/address',
                name: '地址管理',
                component: () => import('../views/address/address.vue'),
            },
              // address 地址详情
              {
                path: '/addressdetail/addressdetail',
                name: '地址详情',
                component: () => import('../views/address/addressdetail.vue'),
                meta: {isfan: true }
            },
             // deliver 发货详情管理
             {
                path: '/deliver/deliver',
                name: '发货详情管理',
                component: () => import('../views/deliver/deliver.vue'),
            },
             // role 角色管理
             {
                path: '/role/role',
                name: '角色管理',
                component: () => import('../views/role/role.vue'),
            },
             // menu 菜单管理
             {
                path: '/menu/menu',
                name: '菜单管理',
                component: () => import('../views/menu/menu.vue'),
            },
             // often 用户追踪
             {
                path: '/often/often',
                name: '用户追踪',
                component: () => import('../views/often/often.vue'),
            },
             // shipments 已发货单据
             {
                path: '/shipments/shipments',
                name: '已发货单据',
                component: () => import('../views/shipments/shipments.vue'),
                meta: {keepAlive: true }
            },
            // shipments 发货详情信息管理
            {
                path: '/shipdetail/shipdetail',
                name: '发货详情',
                component: () => import('../views/shipments/shipdetail/shipdetail.vue'),
                meta: {isfan: true }
            },
            // unloadpost 待审核订单
            {
                path: '/unloadpost/unloadpost',
                name: '待审核订单',
                component: () => import('../views/post/unloadpost/unloadpost.vue'),
                meta: {keepAlive: true }
            },
            // unsendpost 待发货订单
            {
                path: '/unsendpost/unsendpost',
                name: '待分仓订单',
                component: () => import('../views/post/unsendpost/unsendpost.vue'),
                meta: {keepAlive: true }
            },
            // sendpost 订单发货
            {
                path: '/sendpost/sendpost',
                name: '订单发货',
                component: () => import('../views/post/sendpost/sendpost.vue'),
                meta: {isfan:true}
            },
             {
                path: '/sendpost/lcsendpost',
                name: '临采订单发货',
                component: () => import('../views/post/sendpost/lcsendpost.vue'),
                meta: {isfan:true}
            },
             // unsendpost 订单详情
             {
                path: '/postdetail/postdetail',
                name: '订单详情',
                component: () => import('../views/post/postdetail/postdetail.vue'),
                meta: {keepAlive: true ,isfan:true}
            },
             // client 用户管理
             {
                path: '/client/client',
                name: '用户管理',
                component: () => import('../views/client/client.vue'),
            },
            // stock 入库管理
            {
                path: '/stock/stock',
                name: '采购单入库审核',
                component: () => import('../views/stock/stock.vue'),
                meta: {keepAlive: true }
            },
            // stock 采购单据锁定
            {
                path: '/stockhistory/stockhistory',
                name: '采购单据锁定',
                component: () => import('../views/stock/stockhistory/stockhistory.vue'),
                meta: {keepAlive: true }
            },
             // stock 采购入库确认
             {
                path: '/stocksure/stocksure',
                name: '采购入库确认',
                component: () => import('../views/stock/stocksure.vue'),
                meta: {keepAlive: true }
            },
             {
                path: '/stockDetail/stockDetail',
                name: '库存单详情',
                component: () => import('../views/stock/stockDetail/stockDetail.vue'),
                meta: {isfan: true }
            },
            {
                path: '/outstock/outstock',
                name: '退货单填报',
                component: () => import('../views/outstock/outstock.vue'),
            },
             {
                path: '/outpostlist/outpostlist',
                name: '退货单管理',
                component: () => import('../views/outstock/outpostlist.vue'),
            },
            {
                path: '/outpostc/outpostc',
                name: '退厂单管理',
                component: () => import('../views/outstock/outpostc.vue'),
            },
            {
                path: '/outstock/outpostdetail',
                name: '退货单详情',
                meta:{isfan:true},
                component: () => import('../views/outstock/outpostdetail.vue'),
            },
             {
                path: '/outpostpay/outpostpay',
                name: '退款单管理',
                component: () => import('../views/outstock/outpostpay.vue'),
            },
             {
                path: '/outstock/emitretrundetail',
                name: '退货单详情',
                meta:{isfan:true},
                component: () => import('../views/outstock/emitretrundetail.vue'),
            },
            {
                path: '/outstock/surepostdetail',
                name: '确认退货',
                meta:{isfan:true},
                component: () => import('../views/outstock/surepostdetail.vue'),
            },
            {
                path: '/outstock/surepostdetailc',
                name: '确认退货',
                meta:{isfan:true},
                component: () => import('../views/outstock/surepostdetailc.vue'),
            },
            {
                path: '/retrunfactory/retrunlist',
                name: '退厂单管理',
                component: () => import('../views/retrunfactory/retrunlist.vue'),
            },
             // zxpost 装修公司订单管理
             {
                path: '/zxpost/zxpost',
                name: '装修公司订单管理',
                component: () => import('../views/zxpost/zxpost.vue'),
                meta: {keepAlive: true }
            },
             // zxpost 装修公司订单审核管理
             {
                path: '/zxunload/zxunload',
                name: '装修公司订单审核管理',
                component: () => import('../views/zxpost/unloadpost/unloadpost.vue'),
                meta: {keepAlive: true }
            },
             //  管理装修公司订单
             {
                path: '/salesmanzxpost/salesmanzxpost',
                name: '装修公司订单审核管理',
                component: () => import('../views/salesman/zxpost.vue'),
                meta: {isfan: true }
            },
             // zxunpay 装修公司订单详情
             {
                path: '/zxunpay/zxunpay',
                name: '装修公司未结算订单',
                component: () => import('../views/zxpost/unpaypost/unpaypost.vue'),
                meta: {keepAlive: true }
            },
             // zxpost 装修公司订单详情
             {
                path: '/zxdetail/zxdetail',
                name: '装修公司订单详情',
                component: () => import('../views/zxpost/postdetail/postdetail.vue'),
                meta: {isfan: true }
            },
             // postdata 装修公司订单统计
             {
                path: '/zxpostdata/zxpostdata',
                name: '装修公司订单统计',
                component: () => import('../views/zxpost/postdata/postdata.vue'),
                meta: {keepAlive: true }
            },
            {
                path: '/postinfos/postinfos',
                name: '订单详情',
                component: () => import('../views/post/postinfos.vue'),
                meta: {isfan: true }
            },

             // quotation 报价单
             {
                path: '/quotation/quotation',
                name: '报价单',
                component: () => import('../views/quotation/quotation.vue'),
                meta: {keepAlive: true }
            },
              // quotation 报价单详情
              {
                path: '/quotationdetail/quotationdetail',
                name: '报价单详情',
                component: () => import('../views/quotation/quotationdetail/quotationdetail.vue'),
                meta: {isfan: true }
            },
             // partner 合伙人审核
             {
                path: '/partner/partner',
                name: '合伙人审核',
                component: () => import('../views/partner/partner.vue'),
            },
            // 业务员管理
            {
                path: '/salesman/salesman',
                name: '业务员管理',
                component: () => import('../views/salesman/salesman.vue'),
            },
             // 短信验证信息
            {
                path: '/notehistory/notehistory',
                name: '短信验证信息',
                component: () => import('../views/notehistory/notehistory.vue'),
            },
             // 装修公司业务员管理
             {
                path: '/zxsalesman/zxsalesman',
                name: '业务员管理',
                component: () => import('../views/salesman/zxsalesman.vue'),
            },
             // 装修公司集采商品管理
             {
                path: '/centralized/centralizedgood',
                name: '集采商品管理',
                component: () => import('../views/centralized/centralizedgood.vue'),
            },
            // 装修公司入库管理
            {
                path: '/zxstock/zxstock',
                name: '入库管理',
                component: () => import('../views/zxstock/stock.vue'),
                meta: {keepAlive: true }
            },
            // 采购单据填报
            {
                path: '/cgdstock/cgdstock',
                name: '采购单据填报',
                component: () => import('../views/stock/cgdstock.vue'),
                meta: {keepAlive: true }
            },
             // 联营代管入库
             {
                path: '/lystock/lystock',
                name: '联营代管入库',
                component: () => import('../views/stock/lystock.vue'),
                meta: {keepAlive: true }
            },
            // 装修公司入库管理详情
            {
                path: '/zxstockDetail/zxstockDetail',
                name: '入库管理详情',
                component: () => import('../views/zxstock/stockDetail/stockDetail.vue'),
                meta: {isfan: true }
            },
             // 装修公司入库管理详情
             {
                path: '/zxsDetail/zxsDetail',
                name: '入库详情',
                component: () => import('../views/zxstock/zxDetail/zxDetail.vue'),
                meta: {isfan: true }
            },
            // 供应商入库管理
            {
                path: '/ghstock/ghstock',
                name: '供应商入库管理',
                component: () => import('../views/ghstock/stock.vue'),
            },
            // 供应商入库管理
            {
                path: '/ghstockDetail/ghstockDetail',
                name: '供应商入库详情',
                component: () => import('../views/ghstock/stockDetail/stockDetail.vue'),
                meta: {isfan: true }
            },
             // 入库审核
             {
                path: '/stocksh/stocksh',
                name: '入库信息',
                component: () => import('../views/stock/stocksh/stocksh.vue'),
                meta: {isfan: true ,keepAlive: true}
            },
            // 创建发货单
            {
                path: '/fensendpost/fensendpost',
                name: '创建发货单',
                component: () => import('../views/post/sendpost/fensendpost.vue'),
                meta: {isfan: true }
            },
             // 计划盘点单据
            {
                path: '/stockcheck/stockcheck',
                name: '计划盘点单据',
                component: () => import('../views/stockcheck/stockcheck.vue'),
                meta: {keepAlive: true }
            },
            {
                path: '/stockcheck/stockaudit',
                name: '计划盘点审核',
                component: () => import('../views/stockcheck/stockaudit.vue'),
            },
            {
                path: '/stockcheck/stockcheckreuslt',
                name: '计划盘点结果',
                component: () => import('../views/stockcheck/stockcheckreuslt.vue'),
            },
            {
                path: '/stockcheck/stockcheckpost',
                name: '计划盘点结果',
                component: () => import('../views/stockcheck/stockcheckpost.vue'),
            },
            {
                path: '/stockcheck/stockauditdetail',
                name: '计划盘点审核详情',
                meta: {isfan: true },
                component: () => import('../views/stockcheck/stockauditdetail.vue'),
            },
            {
                path: '/stockcheck/emitcheck',
                name: '盘点数据填报',
                component: () => import('../views/stockcheck/emitcheck.vue'),
                meta: {isfan: true }
            },
            // 查看库存
            {
                path: '/stocklist/stocklist',
                name: '查看库存',
                component: () => import('../views/stock/stocklist.vue'),
            },
            {
                path: '/importstock/importstock',
                name: '库存信息导入',
                component: () => import('../views/stock/importstock.vue'),
                meta:{isfan:true}
            },
            {
                path: '/importstock/importfail',
                name: '库存导入失败记录',
                component: () => import('../views/stock/importfail.vue'),
                meta:{isfan:true}
            },
            // 盘点计划详情
            {
                path: '/checkDetail/checkDetail',
                name: '盘点计划详情',
                component: () => import('../views/stockcheck/checkDetail.vue'),
                meta: {isfan: true }
            },
            // 报损单管理
            {
                path: '/reduce/reduce',
                name: '报损单管理',
                component: () => import('../views/stockcheck/reduce/reduce.vue'),
                meta: {keepAlive: true }
            },
            // 报损单详情
            {
                path: '/reducedetail/reducedetail',
                name: '报损单详情',
                component: () => import('../views/stockcheck/reduce/reducedetail.vue'),
                meta: {isfan: true }
            },
            // 报溢单管理
            {
                path: '/increase/increase',
                name: '报溢单管理',
                component: () => import('../views/stockcheck/increase/increase.vue'),
                meta: {keepAlive: true }
            },
            // 报溢单详情
            {
                path: '/increasedetail/increasedetail',
                name: '报溢单详情',
                component: () => import('../views/stockcheck/increase/increasedetail.vue'),
                meta: {isfan: true }
                
            },
            // 待发货单据
            {
                path: '/fenpost/fenpost',
                name: '待发货单据',
                component: () => import('../views/post/fenpost/fenpost.vue'),
                meta: {keepAlive: true }
            },
            // 服务订单
            {
                path: '/servepost/servepost',
                name: '待服务订单',
                component: () => import('../views/post/servepost.vue'),
                meta: {keepAlive: true }
            },
            // 服务发货管理
            {
                path: '/servefenpost/servefenpost',
                name: '服务发货管理',
                component: () => import('../views/post/sendpost/servefenpost.vue'),
                meta: {isfan: true }
            },
            // 商品详情
            {
                path: '/goodsdetail/goodsdetail',
                name: '商品详情',
                component: () => import('../views/goodsmanage/goodsdetail.vue'),
                meta: {isfan: true }
            },
             // 导入预览
             {
                path: '/importgoods/importgoods',
                name: '导入预览',
                component: () => import('../views/goodsmanage/importgoods.vue'),
                meta: {isfan: true }
            },
             // 导入预览
             {
                path: '/unimportgoods/unimportgoods',
                name: '导入失败记录',
                component: () => import('../views/goodsmanage/unimportgoods.vue'),
                meta: {isfan: true }
            },
             // 集采导入预览
             {
                path: '/importcent/importcent',
                name: '集采导入预览',
                component: () => import('../views/centralized/importcent.vue'),
                meta: {isfan: true }
            },
            // 商品调价
            {
                path: '/checkprice/checkprice',
                name: '商品调价',
                component: () => import('../views/checkprice/checkgoods.vue'),
                meta: {keepAlive: true }
            },
            // 商品调价详情
            {
                path: '/pricedetail/pricedetail',
                name: '商品调价详情',
                component: () => import('../views/checkprice/pricedetail.vue'),
                meta: {isfan: true }
            },
            {
                path: '/word/word',
                name: '敏感词管理',
                component: () => import('../views/word/word.vue'),
            },
            {
                path: '/word/hotword',
                name: '热搜词管理',
                component: () => import('../views/word/hotword.vue'),
            },
            {
                path: '/receipt/receipt',
                name: '收款单管理',
                component: () => import('../views/financial/receipt.vue'),
            },
            {
                path: '/bill/bill',
                name: '付款单管理',
                component: () => import('../views/financial/bill.vue'),
            },
            {
                path: '/incost/incost',
                name: '收入费用单',
                component: () => import('../views/financial/incost.vue'),
            },
            {
                path: '/outcost/outcost',
                name: '支出费用单',
                component: () => import('../views/financial/outcost.vue'),
            },
            {
                path: '/incostdetail/incostdetail',
                name: '收入费用单详情',
                meta:{isfan:true,keepAlive:true},
                component: () => import('../views/financial/incostdetail.vue'),
            },
            {
                path: '/outcostdetail/outcostdetail',
                name: '支出费用单详情',
                meta:{isfan:true,keepAlive:true},
                component: () => import('../views/financial/outcostdetail.vue'),
            },
            {
                path: '/shcostdetail/shcostdetail',
                name: '费用单详情',
                meta:{isfan:true},
                component: () => import('../views/financial/shcostdetail.vue'),
            },
            {
                path: '/emitcostdetail/emitcostdetail',
                name: '费用单编辑',
                meta:{isfan:true},
                component: () => import('../views/financial/emitcostdetail.vue'),
            },
            {
                path: '/verification/verification',
                name: '核销单管理',
                component: () => import('../views/financial/verification.vue'),
            },
            {
                path: '/financial/verficadetail',
                name: '核销单详情',
                component: () => import('../views/financial/verficadetail.vue'),
                meta:{isfan:true}
            },
            {
                path: '/financial/verficapost',
                name: '核销关联订单',
                component: () => import('../views/financial/verficapost.vue'),
                meta:{isfan:true}
            },
            {
                path: '/financial/verpayinfos',
                name: '核销关联收款单',
                component: () => import('../views/financial/verpayinfos.vue'),
                meta:{isfan:true}
            },
            {
                path: '/checksh/checksh',
                name: '核销单审核',
                component: () => import('../views/financial/checksh.vue'),
            },
            {
                path: '/checksh/verficalshdetail',
                name: '核销单审核详情',
                meta:{isfan:true},
                component: () => import('../views/financial/verficalshdetail.vue'),
            },
            {
                path: '/iconinfos/iconinfos',
                name: '图标管理',
                component: () => import('../views/iconinfos/iconinfos.vue'),
            },
            {
                path: '/binding/binding',
                name: '管理员绑定',
                component: () => import('../views/binding/binding.vue'),
                redirect: '/binding/bindbnt',
                children: [
                    {
                        path: '/binding/bindbnt',
                        name: '绑定首页',
                        component: () => import('../views/binding/component/bind.vue'),
                    },
                    {
                        path: '/binding/bindlogin',
                        name: '管理员绑定',
                        component: () => import('../views/binding/component/login.vue'),
                    },
                    {
                        path: '/binding/register',
                        name: '管理员注册',
                        component: () => import('../views/binding/component/register.vue'),
                    },
                    {
                        path: '/binding/submit',
                        name: '管理员申请',
                        component: () => import('../views/binding/component/submit.vue'),
                    },
                ]
            },
            // 二
            {
                path: '/area/area',
                name: '地区信息管理',
                component: () => import('../views/role/address.vue'),
            },
            {
                path: '/addpost/addpost',
                name: '新增订单',
                meta:{
                    isfan:true
                },
                component: () => import('../views/addpost/addpost.vue'),
            },
            {
                path: '/post/emitpostdetail',
                name: '订单修改',
                meta:{
                    isfan:true,keepAlive: true ,
                },
                component: () => import('../views/post/emitpostdetail.vue'),
            },
            {
                path: '/addpost/emitpost',
                name: '订单详情',
                meta:{
                    isfan:true,
                },
                component: () => import('../views/addpost/emitpost.vue'),
            },
            {
                path: '/addpost/postpay',
                name: '订单支付',
                // meta:{isfan:true},
                component: () => import('../views/addpost/postpay.vue'),
            },
            {
                path: '/addpost/copypost',
                name: '订单复制',
                meta:{isfan:true},
                component: () => import('../views/addpost/copypost.vue'),
            },
            {
                path: '/delist/adddelist',
                name: '退厂单详情',
                meta:{isfan:true},
                component: () => import('../views/delist/adddelist.vue'),
            },
            {
                path: '/delist/delist',
                name: '退厂单填报',
                component: () => import('../views/delist/delist.vue'),
            },
            {
                path: '/shipments/retrunsendpost',
                name: '退厂发货单',
                component: () => import('../views/shipments/retrunsendpost.vue'),
            },
            {
                path: '/shipments/retrunsenddetail',
                name: '退厂发货单详情',
                meta:{isfan:true},
                component: () => import('../views/shipments/retrunsenddetail.vue'),
            },
            {
                path: '/delist/emitdelist',
                name: '退厂单详情',
                meta:{isfan:true},
                component: () => import('../views/delist/emitdelist.vue'),
            },
            {
                path: '/delist/addnewdelist',
                name: '退货单详情',
                meta:{isfan:true},
                component: () => import('../views/delist/addnewdelist.vue'),
            },
            {
                path: '/delist/addnewdelistx',
                name: '退厂单详情',
                meta:{isfan:true},
                component: () => import('../views/delist/addnewdelistx.vue'),
            },
            {
                path: '/delist/emitnopost',
                name: '退货单详情',
                meta:{isfan:true},
                component: () => import('../views/delist/emitnopost.vue'),
            },
            {
                path: '/delist/emitnewdelist',
                name: '退货单详情',
                meta:{isfan:true},
                component: () => import('../views/delist/emitnewdelist.vue'),
            },
            {
                path: '/companyfy/companyfy',
                name: '费用类型管理',
                component: () => import('../views/companyfy/companyfy.vue'),
            },
            {
                path: '/outpaylist/outpaylist',
                name: '退款日志',
                component: () => import('../views/paylogs/outpaylist.vue'),
            },
            {
                path: '/fenoutpaylist/fenoutpaylist',
                name: '分账退款日志',
                component: () => import('../views/paylogs/fenoutpaylist.vue'),
            },
            {
                path: '/outpaydetail/outpaydetail',
                name: '退款详情',
                meta:{isfan:true},
                component: () => import('../views/paylogs/outpaydetail.vue'),
            },
            // 采购核销
            {
                path: '/purchase/puchaseverificatlist',
                name: '采购核销单',
                component: () => import('../views/purchase/puchaseverificatlist.vue'),
            },
            {
                path: '/purchase/puschaseverificatdetail',
                name: '采购核销单详情',
                meta:{isfan:true},
                component: () => import('../views/purchase/puschaseverificatdetail.vue'),
            },
            {
                path: '/purchase/puchasepayinfos',
                name: '核销关联付款单',
                meta:{isfan:true},
                component: () => import('../views/purchase/puchasepayinfos.vue'),
            },
            {
                path: '/purchase/puchasepost',
                name: '核销关联采购单',
                meta:{isfan:true},
                component: () => import('../views/purchase/puchasepost.vue'),
            },
            {
                path: '/proxiespost/proxiespost',
                name: '代管订单结算',
                meta:{isfan:true},
                component: () => import('../views/zxpost/proxiespost/proxiespost.vue'),
            },
            {
                path: '/stock/lccgstock',
                name: '临采购单管理',
                component: () => import('../views/stock/lccgstock.vue'),
            },
            {
                path: '/salesman/salespost',
                name: '业务员统计',
                meta:{keepAlive:true},
                component: () => import('../views/salesman/salespost.vue'),
            },
            {
                path: '/salesman/commission',
                name: '业务员提成',
                component: () => import('../views/salesman/commission.vue'),
            },
            {
                path: '/salesman/cominfosaudit',
                name: '业务员提成信息',
                meta:{isfan:true},
                component: () => import('../views/salesman/cominfosaudit.vue'),
            },
            {
                path: '/salesman/cominfosview',
                name: '业务员提成信息',
                meta:{isfan:true},
                component: () => import('../views/salesman/cominfosview.vue'),
            },
            {
                path: '/post/postlog',
                name: '订单日志',
                component: () => import('../views/post/postlog.vue'),
            },
            {
                path: '/financial/payablelist',
                name: '应付款信息',
                component: () => import('../views/financial/payablelist.vue'),
            },
            {
                path: '/financial/receivablelist',
                name: '应收款信息',
                component: () => import('../views/financial/receivablelist.vue'),
            },
            {
                path: '/stockcheck/stockdetaillist',
                name: '出入库明细',
                component: () => import('../views/stockcheck/stockdetaillist.vue'),
            },
            {
                path: '/centralized/centralog',
                name: '合作商品操作日志',
                component: () => import('../views/centralized/centralog.vue'),
            },
            {
                path: '/stock/stockpost',
                name: '库存单管理',
                meta:{keepAlive:true},
                component: () => import('../views/stock/stockpost.vue'),
            },
            {
                path: '/zxpost/retrunpost',
                name: '装企退货信息',
                meta:{keepAlive:true},
                component: () => import('../views/zxpost/retrunpost.vue'),
            },
            {
                path: '/logs/logs',
                name: '管理员日志',
                component: () => import('../views/log/logs.vue'),
            },
            {
                path: '/stock/stockstorage',
                name: '库位信息管理',
                component: () => import('../views/stock/stockstorage.vue'),
            },
            {
                path: '/fenpost/lcfenpost',
                name: '临采发货信息',
                meta:{keepAlive:true},
                component: () => import('../views/post/fenpost/lcfenpost.vue'),
            },

        ]
    },
    {
        path: '/pay/pay',
        name: '订单结算',
        meta: {
            title: '材搬配结算页面'
        },
        component: () => import('../views/zxpost/payinfo.vue')
    },
    {
        path: '/addpost/pay',
        name: '订单结算',
        meta: {
            title: '材搬配结算页面'
        },
        component: () => import('../views/addpost/paydetail.vue')
    },
    {
        path: '/test/test',
        name: '订单结算',
        meta: {
            title: '材搬配结算页面'
        },
        component: () => import('../views/binding/test.vue')
    },
]

const router = new VueRouter({
    //mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
